import { useState, useEffect } from 'react';
import configService from '../../services/config/config.service';

const useMaintenanceConfig = () => {
  const [maintenanceConfig, setMaintenanceConfig] = useState({});

  useEffect(() => {
    configService.getMaintenanceConfig().then(setMaintenanceConfig);
  }, []);

  return {
    maintenanceConfig,
  };
};

export default useMaintenanceConfig;
